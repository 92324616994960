import { rdRequests } from "../httpRequest";

const authenticationRequests = {
    customerLogin: (requestData: any) => rdRequests.get(`webaccesscustomer/customer/login?email=${requestData.email}&password=${requestData.password}`),
    updateTemporaryPassword: (requestData: any) => rdRequests.get(`webaccesscustomer/customer/updatetemporarypassword?userName=${requestData.email}&newPassword=${requestData.newPassword}&confirmPassword=${requestData.confirmPassword}`),
    processSSOLogin: (requestData: any) => rdRequests.get(`WebAccessCustomer/SSO/GetSSOLoggedInUser?uniqueIdentifier=${requestData.sub}&email=${requestData.email}`),
    sendSSODuplicateConfirmationMail: (requestData: any) => rdRequests.get(`WebAccessCustomer/SSO/SendSSODuplicateConfirmationMail?customerEmail=${requestData.EmailAddress}&fName=${requestData.FirstName}&lName=${requestData.LastName}&ssoUserIdentifier=${requestData.SSOSub}`),
    verifyUserAccountSSOLogin: (requestData: any) => rdRequests.get(`WebAccessCustomer/SSO/VerifyUserAccountSSOLogin?customerId=${requestData.customerId}&usertIdentifier=${requestData.usertIdentifier}&expirationTime=${requestData.expirationTime}`)
}

export default authenticationRequests;