import { rdrRequests, rdRequests } from "../httpRequest";

const activityApiRequests = {
  getProgramAndTourDetails: (sessionParameters: any) => rdRequests.post(`WebAccessActivity/Load/ProgramAndTourDetails`, sessionParameters),
  searchSessionData: (values: any, searchPage: number) => rdrRequests.post(`AD/SearchSession?searchPage=${searchPage}`, values),
  getActivityDetails: (sessionParameters: any) => rdRequests.post(`WebAccessActivity/load/LoadActivityDetails`, sessionParameters),
  allowSessionSignupWindow: (sessionSignupWindowParameter: any) => rdRequests.post(`WebAccessActivity/AllowSessionSignupWindow`, sessionSignupWindowParameter),
  getInstructor: (customerName: string) => rdRequests.get(`WebAccessActivity/GetInstructor?customerName=${customerName}`),
  getSignUpPrecartDetails: (signUpPreCartParameters: any) => rdRequests.post(`WebAccessActivity/Load/SignUpPrecartDetails`, signUpPreCartParameters),
  validateActivitySignUp: (activitySignUp: any) => rdRequests.post(`WebAccessActivity/ActivitySignUp`, activitySignUp),
  getProgramSignUpDetails: (programSignUpRequest: any) => rdRequests.post(`WebAccessActivity/GetProgramSignUpDetails`, programSignUpRequest),
  getSelectedMeetingTimesForProgram: (programSignUpRequest: any) => rdRequests.post(`WebAccessActivity/GetSelectedMeetingTimesForProgram`, programSignUpRequest),
  sendTellAFriendMessage: (requestData: any) => rdRequests.post(`WebAccessActivity/SendTellAFriendMessage`, requestData),
  processSignUpModification: (signUpModification: any) => rdRequests.post(`WebAccessActivity/ActivitySignUpModification`, signUpModification),
  getSessionQuestionAnswers: (sessionId: any, confirmationNumber: any) => rdRequests.get(`WebAccessActivity/GetSessionQuestionAnswers?sessionId=${parseInt(sessionId)}&confirmationNumber=${parseInt(confirmationNumber)}`),
  processActivityUpdateInfo: (sessionQuestionAnswers: any) => rdRequests.post(`WebAccessActivity/SaveSessionQuestionAnswers`, sessionQuestionAnswers),
}

export default activityApiRequests;