import { store } from "../store/configureStore";
import axios, { AxiosResponse } from "axios";
import { setAlertMessage } from "../../modules/common/settingsSlice";
import { customerLogOut, setIsSSOTokenExpired } from "../../modules/common/authenticationSlice";

const getRdrApiUrl = (): string => {
    let url = localStorage.getItem("rdrApiUrl");
    if (url)
        return url;
    throw new Error("RdrApiUrl is unknown");
}

const getRdApiUrl = (): string => {
    let url = localStorage.getItem("rdApiUrl");
    if (url && url != undefined)
        return url;
    throw new Error("RdApiUrl is unknown");
}

const rdAxios = axios.create();

const settingsAxios = axios.create();

const rdrAxios = axios.create();



const responseBody = (response: AxiosResponse) => response.data;

const handleErrorResponse = (error: any) => {
    if (error.code === "ERR_CANCELED") {
        return Promise.reject(error);
    } else {
        if (error.response && error.response.status === 401) {
            store.dispatch(setIsSSOTokenExpired(true));
            store.dispatch(customerLogOut({ isOpenLogin: true }));
        } else {
            if (error.config && error.config.url && error.config.url.includes("fd/citypark/namecontains")) {
                return Promise.reject(error);
            } else {
                store.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Error", message: "" }));
                return Promise.reject(error);
            }
        }
    }
}

const handleRDResponse = (response: any) => {
    if (response.data) {
        if (response.data.Response === 1 || response.data.Response === 4) {
            if (response.data.Response === 4) {
                store.dispatch(setAlertMessage({ className: "", header: "Success", message: response.data.Message }));
            }
            response.data = response.data.Result;
            return response;
        }
        else {
            let message = response.data.Response === 3 ? response.data.ExceptionString : response.data.Message;
            store.dispatch(setAlertMessage({ className: "loginFailpopup", header: (response.data.Result && response.data.Result.MessageHeader) ? response.data.Result.MessageHeader : "Message", message: message }));
            return Promise.reject(response);
        }
    }
    return response;
}

rdrAxios.interceptors.request.use((config: any) => {
    config.baseURL = getRdrApiUrl();
    const installIdentity = store.getState().common.applicationSettings?.installIdentity;
    config.headers.tenantId = installIdentity ? installIdentity : "";
    return config;
});

rdrAxios.interceptors.response.use((response: any) => {
    return response;
}, (error) => {
    return handleErrorResponse(error);
});

settingsAxios.interceptors.request.use((config: any) => {
    config.baseURL = String(process.env.REACT_APP_RD_API_URL);
    return config;
});

settingsAxios.interceptors.response.use((response: any) => {
    return handleRDResponse(response);
}, (error) => {
    return handleErrorResponse(error);
});


rdAxios.interceptors.request.use((config: any) => {
    config.baseURL = getRdApiUrl();    
    const accessToken = store.getState().authentication.ssoAccessToken;
    const installIdentity = store.getState().common.applicationSettings?.installIdentity;
    const storeId = store.getState().common.applicationSettings?.webStoreId;
    config.headers.InstallationsIdentity = installIdentity ? installIdentity : "";
    config.headers.StoreId = storeId ? storeId : "111";
    config.headers['Content-Type'] = 'application/json';
    if (accessToken) {        
        config.headers.Authorization = 'Bearer ' + accessToken;
    }
    return config;
}
);

rdAxios.interceptors.response.use((response: any) => {
    return handleRDResponse(response);
}, (error) => {
    return handleErrorResponse(error);
});

export const settingRequest = {
    get: (url: string, params?: URLSearchParams) => settingsAxios.get(url, { params }).then(responseBody)
}

export const rdRequests = {
    get: (url: string, params?: URLSearchParams) => rdAxios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => rdAxios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => rdAxios.put(url, body).then(responseBody),
    delete: (url: string) => rdAxios.delete(url).then(responseBody),
}

export const rdrRequests = {
    get: (url: string, params?: URLSearchParams) => rdrAxios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => rdrAxios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => rdrAxios.put(url, body).then(responseBody),
    delete: (url: string) => rdrAxios.delete(url).then(responseBody)
}

