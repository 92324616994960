import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import settingsHttpRequest from "../../app/api/settings/settingsHttpRequest";
import { WebMenu } from "../../app/models/common/webMenu";
import { FutureBookingsDate } from "../../app/models/common/futureBookingsDate";
import { EnterpriseSetting } from "../../app/models/common/enterpriseSetting";
import { WebSiteSetting } from "../../app/models/common/websiteSetting";
import { hideContentLoader, hidePreLoader, showContentLoader, showPreLoader } from "../loader/loaderSlice";
import { getPopularPlace } from "../facilities/components/search/searchSlice";
import { store } from "../../app/store/configureStore";
import { encryptInstallationIdentity, registerScripts } from "../../app/util/util";
import { onOpenModal } from "../../app/util/ada";
interface SettingsState {
    activeRoute: string,
    webMenu: WebMenu[],
    webSiteSettings: WebSiteSetting | null,
    dynamicConfigMsg: any,
    view: string,
    enterpriseSetting: EnterpriseSetting[],
    applicationSettings: any,
    enterpriseDetail: any,
    futureBookingsDate: FutureBookingsDate,
    isMobileDevice: boolean,
    isTablet: boolean,
    webSecuritySettings: any,
    isSSOAlertAfterVerified: boolean
}

const initialState: SettingsState = {
    activeRoute: "Home",
    webMenu: [],
    webSiteSettings: null,
    dynamicConfigMsg: null,
    view: 'map',
    enterpriseSetting: [],
    applicationSettings: null,
    enterpriseDetail: null,
    futureBookingsDate: {
        FutureBookingStartDate: "",
        FutureBookingEndDate: "",
        IsShowFutureBookingCampsitesEndDate: false,
        FutureBookingCampsitesEndDate: ""
    },
    isMobileDevice: window.innerWidth > 768 ? false : true,
    isTablet: (window.innerWidth > 1024) ? false : true,
    webSecuritySettings: null,
    isSSOAlertAfterVerified: false
}

export const loadEnterprise = async (callback: any) => {
    let enterpriseData = {};
    try {
        const enterprise = await settingsHttpRequest.loadEnterprise(window.location.host);
        enterpriseData = {
            enterpriseName: enterprise.EnterpriseName,
            enterpriseId: enterprise.EnterpriseId,
            installIdentity: enterprise.installIdentity,
            instanceName: (window as any).instanceName, 
            cdnURL: enterprise.CDNUrl, 
            webStoreId: enterprise.WebStoreId, 
            rdApiUrl: enterprise.RdApiUrl, 
            rdrApiUrl: enterprise.RdrApiUrl,
            openIdIssuer: enterprise.OpenIdIssuer,
            openIdClientId: enterprise.OpenIdClientId
        };
        localStorage.setItem("enterpriseData", JSON.stringify(enterpriseData));
        localStorage.setItem("rdApiUrl", enterprise.RdApiUrl);
        localStorage.setItem("rdrApiUrl", enterprise.RdrApiUrl);
        callback(enterpriseData);
    } catch (error: any) {
        callback(null);
    }
}

export const getWebMenu = createAsyncThunk<WebMenu[]>(
    'common/getWebMenu',
    async (_, thunkAPI) => {
        try {
            const webMenu = await settingsHttpRequest.getWebMenu();
            return webMenu;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getWebSiteSettings = createAsyncThunk<WebSiteSetting>(
    'common/getWebSiteSettings',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const webSiteSettings = await settingsHttpRequest.getWebSiteSettings();
            if (webSiteSettings && webSiteSettings.enable_default_mapview) {
                thunkAPI.dispatch(setView('map'));
            }
            else {
                thunkAPI.dispatch(setView('list'));
            }
            if (webSiteSettings && webSiteSettings.enable_popular_places) {
                thunkAPI.dispatch(getPopularPlace({ limit: 10 }));
            }
            return webSiteSettings;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getEnterpriseSetting = createAsyncThunk<EnterpriseSetting[]>(
    'common/getEnterpriseSetting',
    async (_, thunkAPI) => {
        try {
            const enterpriseSetting = await settingsHttpRequest.getEnterpriseSetting();
            return enterpriseSetting ? Object.values(enterpriseSetting) : [];
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getConfigMessage = createAsyncThunk<any>(
    'common/getConfigMessage',
    async (_, thunkAPI) => {
        try {
            const configMessages = await settingsHttpRequest.getConfigMessage();
            let obj: any = {};
            configMessages.forEach((element: any) => {
                obj[element.KeyName] = element.Value;
            });
            thunkAPI.dispatch(hidePreLoader());
            return obj;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFutureBookingStartsEndsDates = createAsyncThunk<FutureBookingsDate>(
    'common/getFutureBookingStartsEndsDates',
    async (_, thunkAPI) => {
        try {
            const futureBookingsDate = await settingsHttpRequest.getFutureBookingStartsEndsDates();
            return futureBookingsDate;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getContactUsSubject = async (callback: any) => {
    try {
        const contactUsSubject = await settingsHttpRequest.getContactUsSubject();
        callback(contactUsSubject.$values);
    } catch (error: any) {
        callback(null);
    }
}
export const submitContactUs = createAsyncThunk<boolean, { requestData: any }>(
    'common/submitContactUs',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await settingsHttpRequest.submitContactUs(requestData);
            thunkAPI.dispatch(hideContentLoader());
            if (response == null) {
                thunkAPI.dispatch(setAlertMessage({ header: "Success", message: `${store.getState().common.dynamicConfigMsg["contactus_msgsentsuccessfully"]}` }));
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: `${store.getState().common.dynamicConfigMsg["contactus_msgsentproblem"]}` }));
            }
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getWebSecuritySettings = createAsyncThunk<any>(
    'common/getWebSecuritySettings',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const webSecuritySettings = await settingsHttpRequest.getWebSecuritySettings();
            registerScripts(`https://maps.googleapis.com/maps/api/js?key=${webSecuritySettings.GoogleApiKey}&libraries=places&callback=Function.prototype`)
            return webSecuritySettings;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const checkAuthorizeLogin = createAsyncThunk<any>(
    'common/checkAuthorizeLogin',
    async (_, thunkAPI) => {
        try {
            const response = await settingsHttpRequest.checkAuthorizeLogin();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCMSPageContent = createAsyncThunk<any, { pageName: string }>(
    'common/getCMSPageContent',
    async ({ pageName }, thunkAPI) => {
        try {
            let response = await settingsHttpRequest.getCMSPageContent(pageName);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const settingsSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setApplicationSettings: (state, action) => {
            state.applicationSettings = action.payload;
        },
        setActiveRoute: (state, action) => {
            state.activeRoute = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setAlertMessage: (state, action) => {
            if (action.payload) {
                let modalElement = document.getElementById("alertModal");
                if (modalElement) {
                    if (action.payload.className) {
                        modalElement.classList.add(action.payload.className);
                    } else {
                        modalElement.classList.remove("loginFailpopup");
                    }
                    (document.getElementById("alertModalHeader") as HTMLElement).innerHTML = action.payload.header;
                    (document.getElementById("alertModalMessage") as HTMLElement).innerHTML = action.payload.message;
                    setTimeout(() => {
                        onOpenModal("alertModal", "alertModalHeader", "btnPopupClose", "alertModalHeader");
                    }, 500);
                }
            }
        },
        setEnterpriseDetail: (state, action) => {
            state.enterpriseDetail = action.payload;
        },
        setIsSSOAlertAfterVerified: (state, action) => {
            state.isSSOAlertAfterVerified = action.payload;
        },
    },
    extraReducers: (builder => {
        builder.addCase(getWebMenu.rejected, (state) => {
            state.webMenu = [];
        });
        builder.addCase(getWebSiteSettings.rejected, (state) => {
            state.webSiteSettings = null;
        });
        builder.addCase(getEnterpriseSetting.rejected, (state) => {
            state.enterpriseSetting = [];
        });
        builder.addCase(getConfigMessage.rejected, (state) => {
            state.dynamicConfigMsg = null;
        });
        builder.addCase(getFutureBookingStartsEndsDates.rejected, (state) => {
            state.futureBookingsDate = initialState.futureBookingsDate;
        });
        builder.addCase(getWebSecuritySettings.rejected, (state) => {
            state.webSecuritySettings = null;
        });

        builder.addMatcher(isAnyOf(getWebMenu.fulfilled), (state, action) => {
            state.webMenu = action.payload;
        });
        builder.addMatcher(isAnyOf(getWebSiteSettings.fulfilled), (state, action) => {
            state.webSiteSettings = action.payload;
        });
        builder.addMatcher(isAnyOf(getEnterpriseSetting.fulfilled), (state, action) => {
            state.enterpriseSetting = action.payload;
        });
        builder.addMatcher(isAnyOf(getConfigMessage.fulfilled), (state, action) => {
            state.dynamicConfigMsg = action.payload;
        });
        builder.addMatcher(isAnyOf(getFutureBookingStartsEndsDates.fulfilled), (state, action) => {
            state.futureBookingsDate = action.payload;
        });
        builder.addMatcher(isAnyOf(getWebSecuritySettings.fulfilled), (state, action) => {
            state.webSecuritySettings = action.payload;
        });
    })
})

export const { setApplicationSettings, setActiveRoute, setView, setAlertMessage, setEnterpriseDetail, setIsSSOAlertAfterVerified } = settingsSlice.actions;