import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { showPreLoader, hidePreLoader, showContentLoader, hideContentLoader } from "../loader/loaderSlice";
import shoppingCartApiRequest from "../../app/api/customer/shoppingCartApiRequest";
import { setShoppingCartTimer, getShoppingCartTimerData, setShoppingCartExpireMinutes } from "../common/timerSlice";
import { setAlertMessage } from "../common/settingsSlice";
import { store } from "../../app/store/configureStore";
import navigationService from "../../app/history/history";
import { onOpenModal } from "../../app/util/ada";
import { setFacilityPreCartDetail } from "../facilities/components/facilityPreCart/facilityPreCartSlice";
import { setSignUpModifyDetails } from "../activities/activitySlice";
import {  replaceBRTag } from '../../app/util/util';
import { Action } from "@remix-run/router";
interface ShoppingCartState {
    shoppingCartList: any,
    otherShoppingCartDetail: any,
    cartCount: number,
    paymentSummary: any,
    responseCount: number,
    donationType:string
}

const initialState: ShoppingCartState = {
    shoppingCartList: null,
    otherShoppingCartDetail: null,
    cartCount: localStorage.getItem('cartCount') && getShoppingCartTimerData().isTimerDisplay ? parseInt(localStorage.getItem('cartCount') || "0") : 0,
    paymentSummary: null,
    responseCount: 0,
    donationType:""
}

export const onItemAddInCart = (dispatch: any, response: any, requestData: any) => {
    if (response.IsSuccess) {
        localStorage.setItem('cart', JSON.stringify(response));
        localStorage.setItem('shoppingCartKey', response.ShoppingCartKey);
        dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
        setTimeout(() => {
            dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: response.StartTime, shoppingCartTimerEndTime: response.EndTime }));
        }, 0);
        if (requestData) {
            let shoppingCartListRequestData = {
                "shoppingCartKey": response.ShoppingCartKey
            };
            dispatch(getShoppingCartList({ requestData: shoppingCartListRequestData }));
            dispatch(getOtherShoppingCartDetail({ requestData: shoppingCartListRequestData }));
        }
        return true;
    } else {
        dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
        return false;
    }
}

export const getShoppingCartList = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/getShoppingCartList',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const shoppingCartList = await shoppingCartApiRequest.getShoppingCartList(requestData);
            const cartCount = shoppingCartList && shoppingCartList.CartEntry && shoppingCartList.CartEntry.$values && shoppingCartList.CartEntry.$values.length > 0 ? shoppingCartList.CartEntry.$values.length : 0;
            const shoppingCartExpireMinutes: any = shoppingCartList && shoppingCartList.CartExpireMinutes > 0 ? shoppingCartList.CartExpireMinutes : 0;
            localStorage.setItem('cartCount', cartCount);
            localStorage.setItem('shoppingCartExpireMinutes', shoppingCartExpireMinutes);
            thunkAPI.dispatch(setCartCount(cartCount));
            thunkAPI.dispatch(setShoppingCartExpireMinutes(shoppingCartExpireMinutes));
            if (cartCount === 0 && store.getState().timer.isShoppingCartTimerDisplay) {
                onEmptyCart(thunkAPI.dispatch);
            }
            thunkAPI.dispatch(hidePreLoader());
            return shoppingCartList;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getOtherShoppingCartDetail = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/getOtherShoppingCartDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            const response = await shoppingCartApiRequest.getOtherShoppingCartDetail(requestData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addDonationShoppingCart = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/addDonationShoppingCart',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await shoppingCartApiRequest.addDonationShoppingCart(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const roundUpDonationShoppingCart = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/roundUpDonationShoppingCart',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await shoppingCartApiRequest.roundUpDonationShoppingCart(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const onEmptyCart = (dispatch: any) => {
    localStorage.removeItem("cart");
    localStorage.removeItem("shoppingCartKey");
    localStorage.removeItem("cartCount");
    localStorage.removeItem("shoppingCartExpireMinutes");
    dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
    dispatch(setShoppingCartList(null));
    dispatch(setCartCount(0));
    dispatch(setShoppingCartExpireMinutes(0));
}

export const emptyCart = createAsyncThunk<boolean>('shoppingCart/emptyCart',
    async (_, thunkAPI) => {
        try {
            if (localStorage.getItem("shoppingCartKey")) {
                thunkAPI.dispatch(showPreLoader());
                const response = await shoppingCartApiRequest.emptyCart({
                    "shoppingCartKey": localStorage.getItem('shoppingCartKey') ? localStorage.getItem('shoppingCartKey') : "00000000-0000-0000-0000-000000000000",
                });
                thunkAPI.dispatch(hidePreLoader());
                onEmptyCart(thunkAPI.dispatch);
            }
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const removeCartEntry = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/removeCartEntry',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.removeCartEntry(requestData);
            thunkAPI.dispatch(hidePreLoader());
            let shoppingCartList = store.getState().shoppingCart.shoppingCartList;
            if (shoppingCartList && shoppingCartList.CartEntry && shoppingCartList.CartEntry.$values && shoppingCartList.CartEntry.$values.length > 1) {
                thunkAPI.dispatch(getShoppingCartList({ requestData }));
            } else {
                onEmptyCart(thunkAPI.dispatch);
            }
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const applyPromoCode = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/applyPromoCode',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await shoppingCartApiRequest.applyPromoCode(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const extendShoppingCartTimer = createAsyncThunk<boolean>(
    'shoppingCart/extendShoppingCartTimer',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let requestData = {
                "shoppingCartKey": localStorage.getItem("shoppingCartKey")
            }
            const response = await shoppingCartApiRequest.extendShoppingCartTimer(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, null);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const CancelCartEntry = createAsyncThunk<boolean, { page: string, requestData: any }>(
    'shoppingCart/CancelCartEntry',
    async ({ page, requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let response = null;
            if (page == "CustomerReservations") {
                response = await shoppingCartApiRequest.cancelCartEntry(requestData);
            }
            else if (page == "HcSignUps") {
                response = await shoppingCartApiRequest.cancelHCSignUp(requestData);
            }
            else if (page == "SignUps") {
                response = await shoppingCartApiRequest.cancelSignUp(requestData);
            }
            else if (page == "CustomerPasses" || page == "CustomerMemberships") {
                response = await shoppingCartApiRequest.cancelCustomerMembershipPass(requestData);
            }
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            if (isSuccess) {
                localStorage.removeItem("preCartData");
                navigationService.navigation("/Customers/ShoppingCart");
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export async function CheckoutShoppingCart(requestData: any, callback: any) {
    const response = await shoppingCartApiRequest.checkoutShoppingCart(requestData);
    if (response) {
        callback(response);
    }
    else {
        callback(null);
    }
}

export const checkoutPaymentResponse = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/checkoutPaymentResponse',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.checkoutPaymentResponse(requestData);
            if (response && response.IsSuccess) {
                onEmptyCart(thunkAPI.dispatch);
                navigationService.navigation("/Customers/PaymentSummary");
                thunkAPI.dispatch(setAlertMessage({ className: "", header: "Success", message: response.ConfirmationMessage }));
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
            }
            thunkAPI.dispatch(hidePreLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const modifyCartEntry = createAsyncThunk<boolean, { page: string, requestData: any, isRedirect: boolean }>(
    'shoppingCart/Modify',
    async ({ page, requestData, isRedirect }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            let response = null;
            let isSuccess = false;
            if (page == "CustomerReservations") {
                const facilityPreCartDetail = store.getState().facilityPreCart.facilityPreCartDetail;
                thunkAPI.dispatch(setFacilityPreCartDetail(null));
                response = await shoppingCartApiRequest.modifyReservation(requestData);
                thunkAPI.dispatch(hidePreLoader());
                if (requestData.startTime && response.TimebaseModifyMessage && response.TimebaseModifyMessage !== "") {
                    thunkAPI.dispatch(setAlertMessage({ className: "", header: "Message", message: replaceBRTag(response.TimebaseModifyMessage) }));
                }
                if (response && response.IsSuccess && response.IsCheckoutButtonShow) {
                    thunkAPI.dispatch(setFacilityPreCartDetail(response));
                    isSuccess = true;
                }
                else {
                    if (response.ErrorMessages && response.ErrorMessages !== "") {
                        thunkAPI.dispatch(setAlertMessage({ className: "", header: "Message", message: replaceBRTag(response.ErrorMessages) }));
                    }
                    if (!requestData.isDateNightChanged) {
                        thunkAPI.dispatch(setFacilityPreCartDetail(response));
                        isSuccess = true;
                    } else {
                        thunkAPI.dispatch(setFacilityPreCartDetail(requestData.isSelectedNewUnit ? response : (facilityPreCartDetail ? Object.assign({}, facilityPreCartDetail) : null)));
                        isSuccess = false;
                    }
                }
                if (isSuccess && isRedirect) {
                    localStorage.setItem("preCartData", JSON.stringify(requestData))
                    navigationService.navigation("/Facilities/SelectReservationPreCart");
                }

            }
            else if (page == "SignUps") {
                const activityPreCartModificationDetail = store.getState().activity.signUpModifyDetails;
                thunkAPI.dispatch(setSignUpModifyDetails(null));
                response = await shoppingCartApiRequest.modifySignUp(requestData);
                thunkAPI.dispatch(hidePreLoader());
                if (response && response.IsSuccess) {
                    thunkAPI.dispatch(setSignUpModifyDetails(response));
                    isSuccess = true;
                }
                else {
                    thunkAPI.dispatch(setAlertMessage({ className: "", header: "Message", message: response.ErrorMessages }));
                    if (!requestData.isDateNightChanged) {
                        thunkAPI.dispatch(setSignUpModifyDetails(response));
                        isSuccess = true;
                    } else {
                        thunkAPI.dispatch(setSignUpModifyDetails(Object.assign({}, activityPreCartModificationDetail)));
                        isSuccess = false;
                    }
                }
                if (isSuccess && isRedirect) {
                    localStorage.setItem("signupModifyPreCartDataRequest", JSON.stringify(requestData))
                    navigationService.navigation("/Activities/ActivityModification");
                }
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }
            return isSuccess;

        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return false;
        }
    }
)

export const InitiatePayment = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/InitiatePayment',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.initiatePayment(requestData);
            if (response && response.IsSuccess) {
                let Redirecturl = response.Redirecturl;
                localStorage.setItem("TylerPaymentId", response.PaymentId);
                window.location.href = Redirecturl.replace(/^https:\/\//i, 'http://');
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
            }
            thunkAPI.dispatch(hidePreLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getTylerPaymentResponse = async (dispatch: any, callback: any) => {
    try {
        let responseCount = store.getState().shoppingCart.responseCount;
        dispatch(setResponseCount(responseCount + 1));
        let tylerPaymentId = localStorage.getItem("TylerPaymentId");
        let tylerPaymentRespose = await shoppingCartApiRequest.getTylerPaymentResponse(tylerPaymentId);
        if (tylerPaymentRespose != null) {
            if (tylerPaymentRespose.IsSuccess == false) {
                setTimeout(() => { 
                    callback(tylerPaymentRespose);
                }, 3000)
            }
            else if (tylerPaymentRespose.IsSuccess == true && tylerPaymentRespose.PaymentProcessStatus == "Completed") {
                dispatch(setResponseCount(0));
                onEmptyCart(dispatch);
                callback(tylerPaymentRespose);
            }
            
            else {
                if ((responseCount + 1) > 15) {
                    dispatch(setResponseCount(0));
                    tylerPaymentRespose.IsSuccess = false;
                    tylerPaymentRespose.ErrorMessage = "Payment Failed!";
                    callback(tylerPaymentRespose);
                } else {
                    
                        setTimeout(() => {
                            getTylerPaymentResponse(dispatch, (response: any) => {
                                callback(response);
                            })
                        }, 3000)
                    
                }
            }
        }
        else {
            callback(null);
        }
    } catch (error: any) {
        callback(null);
    }
}

export const InitiateAutopayRegistration = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/InitiateAutopayRegistration',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.initiateAutopayRegistration(requestData);
            if (response && response.IsSuccess) {
                let Redirecturl = response.Redirecturl;
                localStorage.setItem("TylerPaymentId", response.PaymentId);
                window.location.href = Redirecturl.replace(/^https:\/\//i, 'http://');
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
            }
            thunkAPI.dispatch(hidePreLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        setShoppingCartList: (state, action) => {
            state.shoppingCartList = action.payload;
        },
        setCartCount: (state, action) => {
            state.cartCount = action.payload;
        },
        setResponseCount: (state, action) => {
            state.responseCount = action.payload;
        },
        setTylerPaymentSummary: (state, action) => {
            state.paymentSummary = action.payload;
        },
        setDonationType:(state,action) => {
            state.donationType =action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getShoppingCartList.rejected, (state) => {
            state.shoppingCartList = null;
        });
        builder.addCase(getOtherShoppingCartDetail.rejected, (state) => {
            state.otherShoppingCartDetail = null;
        });
        builder.addCase(checkoutPaymentResponse.rejected, (state) => {
            state.paymentSummary = null;
        });
        builder.addMatcher(isAnyOf(getShoppingCartList.fulfilled), (state, action) => {
            state.shoppingCartList = action.payload;
        });
        builder.addMatcher(isAnyOf(getOtherShoppingCartDetail.fulfilled), (state, action) => {
            state.otherShoppingCartDetail = action.payload;
        });
        builder.addMatcher(isAnyOf(checkoutPaymentResponse.fulfilled), (state, action) => {
            state.paymentSummary = action.payload;
        });
    })
})

export const { setShoppingCartList, setCartCount, setResponseCount, setTylerPaymentSummary,setDonationType } = shoppingCartSlice.actions;