import React, { useEffect, useState, lazy, Suspense } from 'react';
import setColorsFromThemeJsonTS from './app/layout/css/theme';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/store/configureStore";
import './app/layout/css/bootstrap.css';
import './app/layout/css/style.css';
import { setApplicationSettings, loadEnterprise, setEnterpriseDetail } from "./modules/common/settingsSlice";
import { PreLoader } from './modules/loader/Loader';
const RouteComponent = lazy(() => import('./modules/Route'));

const LocationWrapper = ({ children }: any) => {
  const location = useLocation();
  const removePreCartData = () => {
    if (!location.pathname.includes("SelectReservationPreCart")) {
      localStorage.removeItem("preCartData");
    }
    if (!location.pathname.includes("park/")) {
      localStorage.removeItem("tempPreCartData");
    }
  }
  const removeSignPreCartData = () => {
    if (!location.pathname.includes("SelectSignUpPreCart") && !location.pathname.includes("Details") && !location.pathname.includes("TellAFriend")) {
      localStorage.removeItem("ActivitySignUp");
      localStorage.removeItem("ActivitySessionID");
    }
  }
  useEffect(() => {
    removePreCartData()
    removeSignPreCartData()
  }, []);

  useEffect(() => {
    removePreCartData();
    removeSignPreCartData();
  }, [location.pathname]);
  return children
}

function App() {
  const [baseName, setBaseName] = useState("");
  const dispatch = useAppDispatch();
  const common = useAppSelector(state => state.common);

  useEffect(() => {
    
    loadEnterprise((enterprise: any) => {
      if (enterprise) {
        setColorsFromThemeJsonTS(enterprise.enterpriseName);
        let data: any = {
          installIdentity: enterprise.installIdentity,
          webStoreId: enterprise.webStoreId,
          imageBaseUrl: enterprise.cdnURL ? enterprise.cdnURL : "",
          dateFormat: "MM/dd/yyyy",
          apiDateFormat: "yyyy-MM-dd",
          defaultParkListSort: "Distance",
          defaultUnitSort: "availability",
          openIdIssuer: enterprise.openIdIssuer,
          openIdClientId: enterprise.openIdClientId
        };

        dispatch(setApplicationSettings(data));
        dispatch(setEnterpriseDetail(enterprise));
      }
    });

    setTimeout(() => {
      setBaseName((window as any).instanceName);
    }, 0);
  }, []);

  return (
    <Suspense fallback={<PreLoader isPreLoaderShow={true} />}>
      {/* <ErrorBoundary> */}
      <Router basename={baseName}>
        <LocationWrapper>
          {common.applicationSettings && common.enterpriseDetail ? <RouteComponent /> : 
          <PreLoader isPreLoaderShow={true} />}
        </LocationWrapper>
      </Router>
      {/* </ErrorBoundary> */}
    </Suspense>
  );
}

export default App;
